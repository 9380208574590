import React from "react";

import { SimpleCell, Avatar } from "@vkontakte/vkui";

import NewsFeedIcons from "./NewsFeedIcons";
import Icon24MoreHorizontal from "@vkontakte/icons/dist/24/more_horizontal";

const NewsFeedItem = ({ image }) => {
  return (
    <>
      <SimpleCell
        description="Час назад • спокойное настроение"
        before={
          <Avatar src="https://sun9-19.userapi.com/c846020/v846020298/1d0e79/R41LGoPtIK0.jpg?ava=1" />
        }
        after={<Icon24MoreHorizontal fill="var(--icon_tertiary)" />}
      >
        Евгений
      </SimpleCell>
      <div
        className="image-holder"
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <NewsFeedIcons />
    </>
  );
};

export default NewsFeedItem;
