import React from "react";

import Icon28NewsfeedOutline from "@vkontakte/icons/dist/28/newsfeed_outline";
import Icon28ServicesOutline from "@vkontakte/icons/dist/28/services_outline";
import Icon28MessageOutline from "@vkontakte/icons/dist/28/messages_outline";
import Icon28ClipOutline from "@vkontakte/icons/dist/28/clip_outline";
import Icon28UserCircleOutline from "@vkontakte/icons/dist/28/user_circle_outline";

import Icon28CameraOutline from "@vkontakte/icons/dist/28/camera_outline";
import Icon28Notifications from "@vkontakte/icons/dist/28/notifications";
import Icon16Dropdown from "@vkontakte/icons/dist/16/dropdown";

import "./NewsFeed.css";
import Icon28GlobeOutline from "@vkontakte/icons/dist/28/globe_outline";

import NewsFeedItem from "../components/NewsFeedItem";
import {
  Button,
  FixedLayout,
  Epic,
  Panel,
  Div,
  PanelHeader,
  Tabs,
  Tabbar,
  TabbarItem,
  TabsItem,
} from "@vkontakte/vkui";

const NewsFeed = ({ id, go }) => {
  return (
    <Panel id={id}>
      <Epic
        activeStory="feed"
        tabbar={
          <Tabbar>
            <TabbarItem data-story="feed" selected text="Новости">
              <Icon28NewsfeedOutline />
            </TabbarItem>
            <TabbarItem data-story="services" text="Сервисы">
              <Icon28ServicesOutline />
            </TabbarItem>
            <TabbarItem data-story="messages" label="12" text="Сообщения">
              <Icon28MessageOutline />
            </TabbarItem>
            <TabbarItem data-story="clips" text="Клипы">
              <Icon28ClipOutline />
            </TabbarItem>
            <TabbarItem data-story="profile" text="Профиль">
              <Icon28UserCircleOutline />
            </TabbarItem>
          </Tabbar>
        }
      ></Epic>
      <PanelHeader
        left={
          <Div>
            <Icon28CameraOutline />
          </Div>
        }
        right={
          <Div>
            <Icon28Notifications />
          </Div>
        }
        separator={false}
      >
        <Tabs>
          <TabsItem after={<Icon16Dropdown fill="var(--accent)" />}>
            Новости
          </TabsItem>
          <TabsItem selected>Интересное</TabsItem>
        </Tabs>
      </PanelHeader>
      <FixedLayout vertical="top">
        <Div className="buttons-wraper">
          <Button mode="secondary">Для Вас</Button>
          <Button mode="outline">Наука</Button>
          <Button mode="outline">Отдых</Button>
          <Button mode="outline">Кино</Button>
        </Div>
      </FixedLayout>

      <div className="new-feed-separator"></div>
      <div className="new-feed-separator"></div>
      <div className="new-feed-separator"></div>
      <div className="new-feed-separator"></div>
      <div className="new-feed-separator"></div>
      <NewsFeedItem image="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Luxembourg_city_streets.jpg/1024px-Luxembourg_city_streets.jpg" />
      <div className="new-feed-separator"></div>
      <NewsFeedItem image="https://www3.pictures.zimbio.com/mp/iS_rUeuh03Vx.jpg" />
      <div className="new-feed-separator"></div>
      <NewsFeedItem image="https://imgcy.trivago.com/c_limit,d_dummy.jpeg,f_auto,h_1300,q_auto,w_2000/itemimages/53/92/53922_v4.jpeg" />
      <div className="new-feed-separator"></div>
      <div className="new-feed-separator"></div>
      <Button className="float-button" onClick={go} data-to="map">
        <Icon28GlobeOutline />
      </Button>
    </Panel>
  );
};

export default NewsFeed;
