import React, { useEffect, useRef } from "react";

import { Div, FixedLayout, Panel, Search } from "@vkontakte/vkui";

import "./Map.css";

const Emoji = (props) => (
  <span
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
);

const MapPage = ({ id }) => {
  const map = useRef(null);

  useEffect(() => {
    window.addEventListener("load", () => {
      window.ymaps.ready(() => {
        let ymaps = window.ymaps;

        const mark = (content, size, text = "") => {
          return ymaps.templateLayoutFactory.createClass(
            `<div class="placemark" style="transform: scale(${size});" ><span class="icon">${content}</span></div>`
          );
        };

        map.current = new window.ymaps.Map("mapcontaier", {
          center: [55.751574, 37.573856],
          zoom: 12,
          controls: [],
        });
        ymaps.templateLayoutFactory.createClass();
        const placeMark = (pos, icon, size) => {
          let mapmark = new ymaps.Placemark(
            pos,
            {},
            {
              iconLayout: mark(icon, size),
            }
          );
          map.current.geoObjects.add(mapmark);
        };

        placeMark([55.751574, 37.573856], "😷", 1);
        placeMark([55.741574, 37.531056], "🍂", 0.9);
        placeMark([55.771574, 37.531056], "💻", 1.1);
        map.current.geoObjects.events.add("click", function () {
          console.log("sss");
          alert("О, событие!");
        });
      });
    });
  }, []);

  return (
    <Panel id={id}>
      <div id="mapcontaier"></div>
      <FixedLayout vertical="bottom">
        <Search placeholder="Поиск по теме и настроению"></Search>
        <Div style={{ background: "#FFFFFF" }}>
          <Emoji symbol="🎧" />
          <Emoji symbol="🍿" />
          <Emoji symbol="🍂" />
          <Emoji symbol="😷" />
          <Emoji symbol="👔" />
        </Div>
      </FixedLayout>
    </Panel>
  );
};

export default MapPage;
