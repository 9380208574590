import React, { useState } from 'react';
import View from '@vkontakte/vkui/dist/components/View/View';

import '@vkontakte/vkui/dist/vkui.css';

import NewsFeed from './panels/NewsFeed';
import Map from './panels/Map';

const App = () => {
	const [activePanel, setActivePanel] = useState(localStorage.getItem("route") || 'newsFeed');

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
		localStorage.setItem("route", e.currentTarget.dataset.to);
	};

	return (
		<View activePanel={activePanel} >
			<NewsFeed id='newsFeed' go={go} />
			<Map id='map' go={go} />
		</View>
	);
}

export default App;

